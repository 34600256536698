import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { media, textClip, transition } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { Link } from '../shared/link';

interface IHelpCard {
	link: string;
	image: string;
	name: string;
	images: any[];
}

export const HelpCard: React.FC<IHelpCard> = ({ ...props }) => {
	const { link, image, name, images } = props;
	const [hovered, setHovered] = useState(false);

	return (
		<a href={link} target="_blank" rel="noreferrer">
			<CaseStudyCardComponent
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				<ImageWrapper>
					<SkImage {...images.find((img) => img.name === image).image} />
				</ImageWrapper>
				<BackgroundWrapper isHovered={hovered}>
					<ContentWrapper data-style="content-wrapper">
						<LinkWrapper isHovered={hovered}>
							<Link variant="default-orange" remixIcon="ri-arrow-drop-right-line">
								{name}
							</Link>
						</LinkWrapper>
					</ContentWrapper>
				</BackgroundWrapper>
			</CaseStudyCardComponent>
		</a>
	);
};

export const CaseStudyCardComponent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	position: relative;

	height: 100%;
	width: 100%;
	& p {
		width: fit-content;
	}

	${media.xl`
			&:hover,
			&:focus-within {
			}
	`};
`;

const ImageWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	& > img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

interface IBackgroundWrapper {
	isHovered: boolean;
}

const BackgroundWrapper = styled.div<IBackgroundWrapper>`
	position: relative;
	width: 100%;
	z-index: 1;
	height: 100%;
	background-image: ${(p) => (p.isHovered ? p.theme.gradients.cardImage : 'none')};
	background-size: 100% 400px, cover;
	background-position: bottom, center;
	background-repeat: no-repeat, no-repeat;
	${transition('all', '', '0.4s')};
`;

const ContentWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding: 24px;
	${transition('background-color', '', '0.4s')};
	cursor: pointer;
`;

const LinkWrapper = styled.div<IBackgroundWrapper>`
	position: absolute;
	bottom: ${(props) => (props.isHovered ? '20px' : '-60px')};
	left: 20px;
	${transition('all', '', '0.4s')};
	& > a {
		justify-content: flex-start;

		& span {
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: ${(p) => p.theme.typography.sizes.t3};

			background-image: ${(p) => p.theme.gradients.button.hover.primary};

			${textClip()};
		}
	}
`;
