import React from 'react';
import styled from 'styled-components';

import {
	Enum_Componentbaseimage_Position,
	Enum_Componentbaselink_Size,
	Enum_Componentbaselink_Variant,
	Enum_Componentgeneralbanner_Contentalign,
	Enum_Componentgeneralbanner_Style,
} from '@schema';

import { media } from '../../styles/mixins';
import { Banner } from '../banner';

const image = {
	id: '52',
	filter: null,
	position: Enum_Componentbaseimage_Position.Center,
	remixIcon: null,
	imageDefault: {
		data: {
			id: '93',
			attributes: {
				name: 'Service Icon.svg',
				alternativeText: 'Service Icon.svg',
				caption: 'Service Icon.svg',
				width: null,
				height: null,
				formats: null,
				hash: 'Service_Icon_f1e0fadf52',
				ext: '.svg',
				mime: 'image/svg+xml',
				size: 1.51,
				url: 'https://www.softkit.dev/static/images/IMG_f532f0c94e.svg',
				previewUrl: null,
				provider: 'aws-s3',
				provider_metadata: null,
				createdAt: '2022-08-30T09:48:56.292Z',
				updatedAt: '2022-08-30T09:48:56.292Z',
				placeholder:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWElEQVQYlWNgIBL8RwIMuCTQVfxvZvjfxfD/8WF0+fkM/8sg0q0M/ysZ/uegGtMDkoIZ3qPzP4Hh/3Lp/xdnY5PuVfu/POZ/N8P/5VpQ6Y2ORDiNsMfwAACqBZOZ0mjFEQAAAABJRU5ErkJggg==',
			},
		},
	},
	imageMobile: {
		data: null,
	},
};

export const HelpGetInTouch = () => {
	return (
		<Container>
			<Wrapper>
				<Banner
					contentAlign={Enum_Componentgeneralbanner_Contentalign.Center}
					id={'openchannel get in touch'}
					style={Enum_Componentgeneralbanner_Style.Transparent}
					title={`{"time":1661856452288,"blocks":[{"id":"_fCinARX92","type":"paragraph","data":{"text":"Contact us for a free IT consultation","alignment":"center"}}],"version":"2.23.2"}`}
					description={`{"time":1661856452288,"blocks":[{"id":"_fCinARX92","type":"paragraph","data":{"text":"Fill out the form below to receive a free consultation and find out how Softkit can help your business grow.","alignment":"center"}}],"version":"2.23.2"}`}
					link={{
						id: 'openchannel get in touch link',
						variant: Enum_Componentbaselink_Variant.Primary,
						text: `Get in touch with us`,
						external: false,
						size: Enum_Componentbaselink_Size.Medium,
					}}
					image={image}
				/>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	background: ${(p) => p.theme.colors.background};
`;

const Wrapper = styled.div`
	max-width: 1460px;
	min-height: auto;
	${media.xl`
        margin: 0 auto;
	`}
	> div {
		z-index: 1;
		min-height: auto;
		padding: 100px 16px;
		${media.md`
            padding: 140px 24px;
	    `}
		${media.xl`
            padding: 160px 80px;
	    `}
        img {
			object-fit: contain !important;
		}
	}
`;
