import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { Breadcrumbs } from '../breadcrumbs';
import { SkImage } from '../shared/image';

export const StandsHero = ({ data, images }) => {
	return (
		<Container>
			<Breadcrumbs isWrapped slug={'/softkit-stands-with-ukraine/'} />
			<Wrapper>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Image>
				<Text>
					{data.blueText} <span>{data.yellowText}</span>
				</Text>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	overflow: hidden;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 55px 16px;
	${media.lg`
        padding: 76px 0;
	`}
`;

const Image = styled.div`
	> img {
		width: 100%;
		height: auto;
	}
`;

const Text = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h3};
	`}
	font-weight: 600;
	line-height: 48px;
	letter-spacing: 0.36px;
	display: flex;
	flex-direction: column;
	color: ${(p) => p.theme.colors.ukrainianBlue};
	text-align: center;
	> span {
		color: ${(p) => p.theme.colors.ukrainianYellow};
	}
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
			line-height: 88px;
		`}
	`}
`;
