import React from 'react';

import { PopupProvider } from '@context/popup-context';

import { Popup } from '../../components/popup';
import { Seo } from '../../components/shared/seo';
import { Develop } from '../../components/stand-with-ukraine/Develop';
import { Help } from '../../components/stand-with-ukraine/Help';
import { HelpGetInTouch } from '../../components/stand-with-ukraine/HelpGetInTouch';
import { StandsHistory } from '../../components/stand-with-ukraine/History';
import { HowCanHelp } from '../../components/stand-with-ukraine/HowCanHelp';
import { StandsHero } from '../../components/stand-with-ukraine/StandsHero';
import MainLayout from '../../layouts/main-layout';

export const StandWithUkraineTemplate = (props: any) => {
	const { menu, header, footer, cookieBanner, theme, ctaPopup, page, seo } = props.pageContext;
	const { GlobalMeta } = seo.data.attributes;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo
				meta={page.attributes.meta}
				globalMeta={GlobalMeta}
				url={'/company/softkit-stands-with-ukraine/'}
			/>
			<PopupProvider>
				{ctaPopup?.data && (
					<Popup
						form={ctaPopup.data.attributes.form}
						formResponse={ctaPopup.data.attributes.formResponse}
					/>
				)}
				<StandsHero data={page.attributes.content.hero} images={page.attributes.images} />
				<StandsHistory data={page.attributes.content.section_1} images={page.attributes.images} />
				<Help data={page.attributes.content.section_2} images={page.attributes.images} />
				<HowCanHelp data={page.attributes.content.section_3} images={page.attributes.images} />
				<Develop data={page.attributes.content.section_4} images={page.attributes.images} />
				<HelpGetInTouch />
			</PopupProvider>
		</MainLayout>
	);
};
