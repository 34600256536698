import React from 'react';
import styled from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const StandsHistory = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Content>
					<Title>{data.title}</Title>
					<Text>{data.text}</Text>
					<Title>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="31"
							height="31"
							viewBox="0 0 24 24"
							fill="none"
						>
							<mask
								id="mask0_4760_145246"
								style={{ maskType: 'alpha' }}
								maskUnits="userSpaceOnUse"
								x="1"
								y="1"
								width="22"
								height="22"
							>
								<path
									d="M21.2955 12.7873L21.3086 12.8017L12.0022 22.2858L2.69572 12.8017L2.70888 12.7873C1.62815 11.5033 1.06349 9.85049 1.12803 8.16014C1.19257 6.46979 1.88155 4.86673 3.05691 3.6722C4.23227 2.47767 5.80718 1.77991 7.46604 1.71874C9.12491 1.65758 10.7452 2.23753 12.0022 3.34238C13.2592 2.23753 14.8794 1.65758 16.5383 1.71874C18.1972 1.77991 19.7721 2.47767 20.9474 3.6722C22.1228 4.86673 22.8118 6.46979 22.8763 8.16014C22.9408 9.85049 22.3762 11.5033 21.2955 12.7873Z"
									fill="#F86B00"
								/>
							</mask>
							<g mask="url(#mask0_4760_145246)">
								<rect x="1.12305" y="1.71436" width="21.7582" height="10.2857" fill="#156DD1" />
								<rect x="1.12305" y="12" width="21.7582" height="10.2857" fill="#FFD948" />
							</g>
						</svg>
						{data.subtitle}
					</Title>
				</Content>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Image>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	max-width: 1460px;
	margin: 0 auto;
	padding: 40px 16px 80px 16px;
	${media.md`
		padding: 60px 24px 120px 24px;
	`}
	${media.xl`
		padding: 80px;
		margin: 0 auto; 
	`}
	${media.xxl`       
		padding: 80px 0;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 24px;
	align-items: center;
	${media.xl`
		display: grid;
        grid-template-columns: 0.6fr 0.4fr;
        gap: 60px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
		gap: 24px;
	`};
`;

const Image = styled.div`
	> img {
		width: 100%;
		height: auto;
		${media.md`
		    width: 380px;
	    `};
		${media.md`
		    width: 100%;
	    `};
	}
`;

const Title = styled.p`
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	color: ${(p) => p.theme.colors.white};
	display: flex;
	flex-direction: row;
	gap: 8px;
	svg {
		width: 20px;
		height: 20px;
		margin-top: 3px;
	}
	${media.md`
		font-size: 18px;
		line-height: 24px;
	`}
	${media.xl`
		font-size: 20px;
		line-height: 28px;
	`}
`;

const Text = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.md`
		font-size: 16px;
		line-height: 22px;
	`}
	${media.xl`
		font-size: 18px;
		line-height: 24px;
	`}
`;
