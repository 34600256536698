import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';

import { HelpCard } from './HelpCard';

export const HowCanHelp = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Header>
					<p data-element="heading">{data.title}</p>
					<p data-element="description">{data.text}</p>
				</Header>
				<Items>
					{data.items.map((item, index) => (
						<Item key={index}>
							<HelpCard image={item.image} link={item.link} name={item.name} images={images} />
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Header = styled.div`
	display: flex;
	flex-direction: column;

	grid-area: header;

	& > * {
		flex: 1;
	}

	height: max-content;
	margin-bottom: 25px;

	[data-element='heading'] {
		position: relative;
		font-weight: 600;

		letter-spacing: 0.01em;

		font-size: ${(p) => p.theme.typography.sizes.h3};

		margin-bottom: 24px;

		&:before {
			content: '';
			position: absolute;
			top: 1ch;
			right: 100%;

			width: 100vw;
			height: 2px;

			background-color: ${({ theme }) => theme.colors.orange1};
		}
		line-height: 133%;
	}

	[data-element='description'] {
		font-weight: 400;
		font-size: ${(p) => p.theme.typography.sizes.t2};
		line-height: 24px;

		text-align: left !important;

		${media.md`
			${(p) => css`
				font-size: ${p.theme.typography.sizes.t3};
			`}
		`};

		${media.lg`
			text-align: right !important;
			${(p) => css`
				font-size: ${p.theme.typography.sizes.t4};
			`}
		`};
	}

	${media.md`
		margin-bottom: 40px;

		[data-element='heading'] {
			${(p) => css`
				font-size: ${p.theme.typography.sizes.t4};
			`}
		}
	`};

	${media.lg`
		gap: 20px;
		justify-content: space-between;
		flex-direction: row;

		margin-bottom: 60px;

		[data-element='heading'] {
			margin-bottom: 0;
			line-height: 140%;
		}
	`};

	${media.xl`
		// move to next line
		& > [data-element='html']:first-child {
			max-width: 60%;
		}

		[data-element='heading'] {
			font-size: 56px;
		}
	`}
`;

const Container = styled.div`
	max-width: 1460px;
	margin: 0 auto;
	padding: 40px 16px 80px 16px;
	${media.md`
		padding: 60px 24px 120px 24px;
	`}
	${media.xl`
		padding: 80px 80px 160px 80px;
		margin: 0 auto; 
	`}
	${media.xxl`       
		padding: 80px 0 160px 0;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
		display: grid;
		grid-template-columns: repeat(2,1fr);
	`}
	${media.xl`
		grid-template-columns: repeat(3,1fr);
	`}
	> div > a {
		width: 100%;
		height: 100%;
	}
`;

const Item = styled.div`
	width: 100%;
	height: 260px;
`;
