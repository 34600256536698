import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const Help = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Image>
				<Content>
					<Title>{data.title}</Title>
					<Text>{data.text}</Text>
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	max-width: 1460px;
	margin: 0 auto;
	padding: 80px 16px;
	${media.md`
		padding: 120px 24px;
	`}
	${media.xl`
		padding: 160px 80px;
		margin: 0 auto; 
	`}
	${media.xxl`       
		padding: 160px 0;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	${media.xl`
		display: grid;
        grid-template-columns: 0.4fr 0.6fr;
        gap: 0;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Text = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: ${(p) => p.theme.colors.gray};
	${media.md`
		font-size: 18px;
		line-height: 24px;
	`}
	text-align: center;
	${media.xl`
		text-align: left;
	`}
`;

const Image = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	> img {
		width: 200px;
		height: auto;
		${media.md`
		    width: 240px;
	    `};
		${media.md`
		    width: 285px;
	    `};
	}
`;

const Title = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	font-weight: 600;
	line-height: 32px;
	color: ${(p) => p.theme.colors.white};
	${media.md`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	text-align: center;
	${media.xl`
		text-align: left;
	`}
`;
